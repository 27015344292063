import React, { Component } from "react";
import { useParams, useHistory, useRouteMatch } from "react-router-dom";

import { GoogleMap } from "@react-google-maps/api";
import MapStyle from "data/map-style.json";
import GoogleMapsMarker from "components/admin/adminmarker.jsx";

/// Google Maps component
class AdminMap extends Component {
  /// Map style
  MapOptions = {
    //styles: MapStyle,
    disableDefaultUI: true,
    zoomControl: true,
    mapTypeId: "satellite",
  };

  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.markers = [];

    this.state = {
      markers: [],
      startPosition: {
        lat: 0.0,
        lng: 0.0,
      },
    };

    this.initialize();
  }

  /// On props change
  componentWillReceiveProps(props) {
    if (this.props.match.params.collection != props.match.params.collection) {
      this.props = props;
      this.initialize();
    }
  }

  /// Initialize map center
  async initialize() {
    await this.props.content.waitForContent();
    this.createMarkers();
  }

  /// Pan to location
  setPosition = ({ lat, lng }) => {
    if (lat && lng) {
      this.mapRef.current.panTo({ lat, lng });
    }
  };

  /// Create markers
  async createMarkers() {
    await this.props.content.waitForContent();

    this.state.markers = [];
    this.markers = [];

    this.props.content.getRawData((raw_data) => {
      for (var i = 0; i < raw_data.length; i++) {
        let item = raw_data[i];
        let ref = React.createRef();
        this.markers.push(ref);
        this.state.markers.push(
          <GoogleMapsMarker
            key={item.uniqueId}
            finished={item.image_published}
            data={item}
            map={this}
            ref={ref}
          />
        );
      }
    });
    this.setState({ markers: this.state.markers });
  }

  /// Main render
  render() {
    return (
      <GoogleMap
        mapContainerStyle={{
          height: `100vh`,
          minHeight: `720px`,
          width: `100%`,
        }}
        zoom={14}
        center={this.state.startPosition}
        options={this.MapOptions}
        onLoad={(ref) => {
          this.mapRef.current = ref;
        }}
      >
        {this.state.markers}
      </GoogleMap>
    );
  }
}
export default PreProcessor(AdminMap);

/// Component PreProcessor ( Passes various hooks )
function PreProcessor(Component) {
  return function WrappedComponent(props) {
    const { collection } = useParams();
    const history = useHistory();
    const match = useRouteMatch({
      path: "/admin/:collection",
      strict: true,
      sensitive: true,
    });
    return (
      <Component
        {...props}
        ref={props.reference}
        history={history}
        match={match}
      />
    );
  };
}
