const { Component } = require("react");

/// Main content manager
/// Used as psuedo-database
class ContentManager extends Component {
  constructor(props) {
    super(props);

    this.data = [];
    this.raw_data = [];
    this.loaded = false;
    this.path = "collections.json";
    this.raw_data_link =
      "https://docs.google.com/spreadsheets/d/1oOYfBLRJGFP2WaBYC6GLw6J0T4_OPj7kiYFrIG4RB9c/export?format=csv&id=1oOYfBLRJGFP2WaBYC6GLw6J0T4_OPj7kiYFrIG4RB9c&gid=0";
    this.admin = false;

    this.loading = this.initialize();
  }

  async initialize() {
    await this.loadData(this.path);
  }

  /// Query entire database
  async searchDatabase(searchword, onFinish) {
    let result = [];

    for (var i = 0; i < this.data.length; i++) {
      let collection = this.data[i];
      for (var j = 0; j < collection.items.length; j++) {
        let item = collection.items[j];
        if (this.isMatched(item, searchword)) {
          // Add to result
          result.push(item);
        }
      }
    }

    if (onFinish) {
      onFinish(result);
    }
    return result;
  }

  /// Query specific collection
  async searchCollection(collection, searchword, onFinish) {
    let result = [];

    for (var i = 0; i < this.data.length; i++) {
      if (this.data[i].collection == collection || collection == undefined) {
        let collection = this.data[i];
        for (var j = 0; j < collection.items.length; j++) {
          let item = collection.items[j];
          if (this.isMatched(item, searchword)) {
            // Add to result
            result.push(item);
          }
        }
      }
    }

    if (onFinish) {
      onFinish(result);
    }
    return result;
  }

  /// Match searchword
  isMatched(item, searchword) {
    let regex = `^.*${searchword.toLowerCase()}.*$`;
    if (searchword != "") {
      let match = false;

      // Match keywords
      for (var j = 0; j < item.keywords.length; j++) {
        let string = item.keywords[j].toLowerCase();
        if (string.match(regex)) {
          match = true;
        }
      }

      // Match collection
      if (item.collection.toLowerCase().match(regex)) {
        match = true;
      }

      // Match name
      if (item.name.toLowerCase().match(regex)) {
        match = true;
      }

      // Match year
      if (searchword.length < 4) {
        if (!match) {
          regex = `^.*${searchword}.*$`;
          match = item.year.toString().match(regex);
        }
      } else {
        // Match within 4 year span
        if (!isNaN(searchword)) {
          let year = parseInt(searchword);
          if (year <= item.year + 2 && year >= item.year - 2) {
            match = true;
          }
        }
      }
      return match;
    }
    return true;
  }

  /// Find image in collection
  async getElement(collection, image, onFinish) {
    for (var i = 0; i < this.data.length; i++) {
      if (this.data[i]["collection"] == collection) {
        for (var j = 0; j < this.data[i]["items"].length; j++) {
          let item = this.data[i]["items"][j];
          if (item.content == image) {
            onFinish(item);
            return;
          }
        }
      }
    }
    onFinish(undefined);
  }

  /// Find image in collection by id
  async getElementById(uniqueId, onFinish) {
    for (var i = 0; i < this.data.length; i++) {
      for (var j = 0; j < this.data[i]["items"].length; j++) {
        let item = this.data[i]["items"][j];
        if (item.uniqueId == uniqueId) {
          onFinish(item);
          return;
        }
      }
    }
    onFinish(undefined);
  }

  /// Get neighbouring items
  async getElementNeighbours(collection, image) {
    let leftNeighbour = undefined;
    let rightNeighbour = undefined;

    for (var i = 0; i < this.data.length; i++) {
      if (this.data[i]["collection"] == collection) {
        for (var j = 0; j < this.data[i]["items"].length; j++) {
          let item = this.data[i]["items"][j];
          if (item.content == image) {
            leftNeighbour =
              this.data[i]["items"][
                this.wrapIndex(this.data[i]["items"].length, j - 1)
              ];
            rightNeighbour =
              this.data[i]["items"][
                this.wrapIndex(this.data[i]["items"].length, j + 1)
              ];
            break;
          }
        }
      }
    }
    return { left: leftNeighbour, right: rightNeighbour };
  }

  /// Returns collection array
  async getCollection(collection, onFinish) {
    for (var i = 0; i < this.data.length; i++) {
      if (this.data[i]["collection"] == collection) {
        onFinish(this.data[i]["items"], this.data[i]);
        return;
      }
    }
    onFinish(undefined, undefined);
  }

  /// Returns raw data array
  async getRawData(onFinish) {
    onFinish(this.raw_data);
  }

  /// Returns all data
  async getData() {
    return this.data;
  }

  /// Create item data
  createItem(item) {
    let created_item = {
      // Identification
      id: item.id,
      uniqueId: item.uniqueId,
      content: item.content || "",
      collection: item.collection || "",
      category: item.category || "",
      keywords: item.keywords || [],

      // Content
      content_path_old: `/media/lowres/${item.content}_0.jpg` || "",
      content_path_new: `/media/lowres/${item.content}_1.jpg` || "",
      content_path_colorized: `/media/colorized/${item.content}_2.jpg` || "",
      content_path_thumbnail_old:
        `/media/thumbnail/${item.content}_0.jpg` || "",
      content_path_thumbnail_new:
        `/media/thumbnail/${item.content}_1.jpg` || "",

      // Info
      name: item.name || "Name",
      year: item.early_yr || 0,
      created: item.late_yr || 0,
      description: item.description || "Description",
      address: item.address || "Adress",
      lng: item.coordinates["lat"],
      lat: item.coordinates["long"],
      alert: item.alert || "",
      note: item.note || "",

      // Legal notes
      link: item.link || "",
      license: item.license || "",
      license_holder: item.license_holder || "",
      license_link: item.license_link || "",
      license_source: item.license_source || "",
      photographer: item.photographer || "",
      legal: item.legal || [],

      // Misc
      upload_date: item.upload_date || [],
      is_new: this.isNew(item.upload_date),
    };
    return created_item;
  }

  async waitForContent() {
    await this.loading;
  }

  /// Load and format all data
  async loadData(path) {
    if (!this.loaded) {
      this.data = [];
      let raw = await import(`data/${path}`);

      for (var i = 0; i < raw["items"].length; i++) {
        let collection = raw["items"][i];
        if (collection["visible"] || this.admin) {
          let cdata = await import(`data/${collection["content"]}.json`);
          let count = 0;
          let has_new = false;
          for (var j = 0; j < cdata["items"].length; j++) {
            if (cdata["items"][j]["visible"] || this.admin) {
              cdata["items"][j].uniqueId = (i + 1) * 100 + j;
              cdata["items"][j].collection = collection["content"];
              // Reformat data
              cdata["items"][j] = this.createItem(cdata["items"][j]);
              if (cdata["items"][j].is_new) {
                has_new = true;
              }
              count++;
            } else {
              cdata["items"].splice(j, 1);
              j--;
            }
          }
          cdata.has_new = has_new;
          cdata.count = count;
          cdata.position = {
            x: collection["position"]["x"],
            y: collection["position"]["y"],
            long: collection["position"]["long"],
            lat: collection["position"]["lat"],
          };
          this.data.push(cdata);
        }
      }

      await fetch(this.raw_data_link)
        .then((r) => r.text())
        .then((text) => {
          let raw_csv = [];
          raw_csv = text.split("\n");

          for (var i = 0; i < raw_csv.length; i++) {
            raw_csv[i] = raw_csv[i].replace("\r", "").split(",");

            raw_csv[i] = {
              uniqueId: i * 100,
              name: raw_csv[i][0],
              licens: raw_csv[i][1],
              image_edited: raw_csv[i][2],
              image_taken: raw_csv[i][3],
              photo_type: raw_csv[i][4],
              source_link: raw_csv[i][5],
              year: raw_csv[i][6],
              description: raw_csv[i][7],
              position: {
                lat: parseFloat(raw_csv[i][8].replace('"', "")),
                lng: parseFloat(raw_csv[i][9].replace('"', "")),
              },
              lat: parseFloat(raw_csv[i][8].replace('"', "")),
              lng: parseFloat(raw_csv[i][9].replace('"', "")),
              preferred_time_of_year: raw_csv[i][10],
              image_published: raw_csv[i][11] == "Ja",
            };
          }

          raw_csv.shift();
          this.raw_data = raw_csv;
        });

      this.loaded = true;
    }
  }
  /*
    // Formatting
    {
        "name": Collection name,
        "collection" Collection data name,
        "count": Collection size,
        "position":
        {
          "x": Collection position x,
          "y": Collection position y
        },
        "items": Array of images
    }
  */

  /// Wrap id
  wrapIndex(length, index) {
    if (index < 0) {
      return length - 1;
    }
    if (index >= length) {
      return 0;
    }
    return index;
  }

  /// Checks is item is newly uploaded
  isNew(utcTime) {
    if (utcTime == undefined) return false;
    let current_utc_time = new Date().getTime();
    let new_time = 2629743830; // One month in milliseconds
    if (current_utc_time - utcTime > new_time) {
      return false;
    }
    return true;
  }
}
export default ContentManager;
