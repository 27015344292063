import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import "app.css";
import "theme.css";
import "bootstrap/dist/css/bootstrap.css";

import Container from "components/container.jsx";
import Collections from "components/collections.jsx";
import Admin from "components/admin/admin.jsx";
import Viewer from "components/viewer.jsx";
import Header from "components/header.jsx";
import Footer from "components/footer.jsx";
import ContentManager from "components/content-manager.jsx";

class App extends Component {
  /// Component references
  containerRef = React.createRef();
  adminRef = React.createRef();
  viewerRef = React.createRef();
  headerRef = React.createRef();
  collectionsRef = React.createRef();

  /// Main content manager
  /// Used as database
  content = new ContentManager();

  /// Main app render
  render() {
    return (
      <div className="container-fluid background-gradient-base">
        {/* Background video*/}
        <Route exact path="/">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <video autoPlay muted loop className="background-video">
              <source src="./media/background.mp4" type="video/mp4"></source>
            </video>
          </div>
        </Route>

        {/* Header */}
        <Header reference={this.headerRef} app={this} />

        <div className="row content content-wrapper noise">
          {/* Collection images menu */}
          <Route exact path="/:collection?/:image?">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <Container reference={this.containerRef} app={this} />
            </div>
          </Route>

          {/* Collections menu */}
          <Route exact path="/">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 pl-0 pr-0">
              <Collections reference={this.collectionsRef} app={this} />
            </div>
          </Route>

          <Switch>
            {/* Admin */}
            <Route exact path="/admin/:collection/:image?">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <Admin ref={this.adminRef} app={this} />
              </div>
            </Route>

            {/* Image viewer */}
            <Route exact path="/:collection/:item">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <Viewer ref={this.viewerRef} app={this} />
              </div>
            </Route>
          </Switch>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}

export default App;
