import React, { Component } from "react";
import { useParams, useHistory, useRouteMatch } from "react-router-dom";
import MetaTags from "react-meta-tags";

import "components/viewer.css";
import Map from "components/googlemaps.jsx";

/// Main image viewer
class Viewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      element: {},
      past_image: `/media/lowres/${this.props.match.params.image}_0.jpg`,
      past_colorized_image: "",
      present_image: `/media/lowres/${this.props.match.params.image}_1.jpg`,
      past_colorized_image_src: "",
      pageBackElement: undefined,
      pageForwardElement: undefined,
      fullscreen: false,
      visible: false,
      forceColorizedCheckbox: false,

      // Styles
      splitStyle: {
        width: `50%`,
        transition: `none`,
      },
      loadingStyle: {
        opacity: `0.0`,
      },
      imageStyle: {
        filter: "blur(0px)",
      },
      pastImgStyle: {
        backgroundImage: `url("")`,
      },
      colorizedButtonStyle: {
        display: "block",
      },
    };

    this.map = React.createRef();
    this.viewer = React.createRef();

    this.colorized = false;
    this.past_colorized_image = "";

    this.mouseDown = false;

    this.pastImageLoaded = false;
    this.presentImageLoaded = false;
    this.pastColorizedImageLoaded = false;
    this.loadingItemsCount = 0;
    this.forceDisableFocus = false;

    this.changeElement(
      this.props.match.params.collection,
      this.props.match.params.image
    );
  }

  /// On props change
  componentWillReceiveProps(props) {
    if (this.props != props) {
      this.props = props;
      this.changeElement(
        props.match.params.collection,
        props.match.params.image,
        false
      );
    }
  }

  /// Change current element viewed
  async changeElement(collection, image, focus) {
    if (this.props.app.headerRef.current) {
      this.props.app.headerRef.current.resetTextBox();
    }

    await this.props.app.content.waitForContent();

    this.props.app.content.getElement(collection, image, (element) => {
      this.forceDisableFocus = false;
      this.setState({ element: element });

      this.setupNeighbours(element.collection, element.content);

      // Target on map
      if (this.map) {
        this.map.current.targetMarker(element.content);
      }

      // Set content
      this.setState({
        present_image: element.content_path_new,
        past_image: element.content_path_old,
        past_colorized_image: element.content_path_colorized,
      });
      this.past_colorized_image = element.content_path_colorized;

      // Start loading content
      this.pastImageLoaded = false;
      this.presentImageLoaded = false;
      this.pastColorizedImageLoaded = false;
      this.loadingItemsCount = 0;

      this.checkColorizedExists(element);
      this.updateColorized(this.colorized);

      this.loadingItemsCount++;
      this.loadingItemsCount++;
      this.startLoadingScreen();

      // Scroll focus on image
      if (focus != undefined) {
        this.forceDisableFocus = !focus;
      }
    });
  }

  /// Mouse input handler
  onViewerMouseLeave = (event) => {
    this.mouseDown = false;
  };
  onViewerMouseDown = (event) => {
    this.mouseDown = true;
    this.onViewerMouseMove(event);
  };
  onViewerMouseUp = (event) => {
    this.mouseDown = false;
    this.onViewerMouseMove(event);
  };

  /// Image split movement
  onViewerMouseMove = (event) => {
    if (this.mouseDown) {
      let mouseX = event.clientX;
      let mouseY = event.clientY;

      if (
        event.type == "touchstart" ||
        event.type == "touchmove" ||
        event.type == "touchend" ||
        event.type == "touchcancel"
      ) {
        var touch = event.touches[0] || event.changedTouches[0];
        mouseX = touch.pageX;
        mouseY = touch.pageY;
      }

      let viewerX = this.viewer.current.getBoundingClientRect().x;
      let viewerWidth = this.viewer.current.getBoundingClientRect().width;

      let viewerY = this.viewer.current.getBoundingClientRect().y;
      let viewerHeight = this.viewer.current.getBoundingClientRect().height;

      // Viewer split percentage
      let percentage = ((mouseX - viewerX) / viewerWidth) * 100.0;
      if (percentage < 0.0) {
        percentage = 0.0;
      }
      if (percentage > 100.0) {
        percentage = 100.0;
      }

      this.setState({
        splitStyle: {
          width: `${percentage}%`,
          transition: `none`,
        },
      });
    }
  };

  /// Reset view split
  centerSplit = () => {
    this.setState({
      splitStyle: {
        width: `50%`,
        transition: `width ease-out 1.0s`,
      },
    });
  };

  /// Focus / Scroll to image
  focusOnImage = () => {
    if (!this.forceDisableFocus) {
      document.getElementById("viewer").scrollIntoView({ behavior: "smooth" });
    }
  };

  /// Back to collection
  onBackClick = (event) => {
    this.props.history.push("/" + this.state.element.collection);
  };

  /// Navigate left / right
  onPageBack = (event) => {
    this.props.history.push(
      "/" +
        this.state.pageBackElement.collection +
        "/" +
        this.state.pageBackElement.content
    );
    this.changeElement(
      this.state.pageBackElement.collection,
      this.state.pageBackElement.content,
      false
    );
  };
  onPageForward = (event) => {
    this.props.history.push(
      "/" +
        this.state.pageForwardElement.collection +
        "/" +
        this.state.pageForwardElement.content
    );
    this.changeElement(
      this.state.pageBackElement.collection,
      this.state.pageBackElement.content,
      false
    );
  };

  /// Enlarge view
  onFullscreen = (event) => {
    this.setState({ fullscreen: event.target.checked });
  };
  getFullscreenClasses = () => {
    if (this.state.fullscreen) {
      return "viewer-inner-container col-lg-10 col-md-10 content";
    }
    return "viewer-inner-container col-lg-6 col-md-8 content";
  };
  getFullscreenClassesSide = () => {
    if (this.state.fullscreen) {
      return "viewer-inner-container col-lg-1 col-md-1";
    }
    return "viewer-inner-container col-lg-3 col-md-2";
  };

  /// Colorize view
  onColorize = (event) => {
    this.updateColorized(event.target.checked);
  };
  updateColorized = (value) => {
    this.colorized = value;
    this.setState({ forceColorizedCheckbox: value });
    if (value) {
      if (this.requestColorizedPhoto()) {
        this.showColorized();
      }
    } else {
      this.hideColorized();
      if (!this.pastColorizedImageLoaded) {
        this.stopLoadingScreen();
      }
    }
  };

  /// Enable colorized photo
  showColorized = () => {
    this.setState({
      pastImgStyle: {
        backgroundImage: `url(${this.state.past_colorized_image})`,
      },
    });
  };

  /// Disable colorized photo
  hideColorized = () => {
    this.setState({
      pastImgStyle: {
        backgroundImage: `url(${this.state.past_image})`,
      },
      past_colorized_image_src: "",
    });
  };

  /// Request colorized photo / load photo
  requestColorizedPhoto = () => {
    if (!this.pastColorizedImageLoaded) {
      this.loadingItemsCount++;
      this.startLoadingScreen();
      this.setState({
        past_colorized_image_src: this.past_colorized_image,
      });
      return false;
    }
    return true;
  };

  /// Check if colorized image is available
  checkColorizedExists = (element) => {
    var http = new XMLHttpRequest();

    // Check if jpg exists
    http.open("HEAD", element.content_path_colorized, false);
    http.send();
    if (http.status != 404) {
      this.setState({ colorizedButtonStyle: { display: "block" } });
      return true;
    }

    this.hideColorized();
    this.setState({ colorizedButtonStyle: { display: "none" } });
    return false;
  };

  /// Start image loading screen
  startLoadingScreen = () => {
    this.setState({
      loadingStyle: {
        opacity: `1.0`,
      },
      imageStyle: {
        filter: "blur(20px)",
      },
    });
    setTimeout(() => {
      this.stopLoadingScreen(() => {});
    }, 7000);
  };

  /// Stop loading screen
  stopLoadingScreen = (onFinished) => {
    this.loadingItemsCount = 0;
    setTimeout(() => {
      this.setState({
        loadingStyle: {
          opacity: `0.0`,
        },
        imageStyle: {
          filter: "blur(0px)",
        },
      });
      if (onFinished) {
        onFinished();
      }
    }, 350);
  };

  /// On past image loaded event
  pastLoaded = () => {
    this.pastImageLoaded = true;
    this.loadingItemsCount--;
    if (this.presentImageLoaded && this.loadingItemsCount <= 0) {
      this.stopLoadingScreen(() => {
        this.focusOnImage();
        this.centerSplit();
      });
      this.updateColorized(this.colorized);
    }
  };

  /// On present image loaded event
  presentLoaded = () => {
    this.presentImageLoaded = true;
    this.loadingItemsCount--;
    if (this.pastImageLoaded && this.loadingItemsCount <= 0) {
      this.stopLoadingScreen(() => {
        this.focusOnImage();
        this.centerSplit();
      });
      this.updateColorized(this.colorized);
    }
  };

  /// On colorized image loaded event
  pastColorizedLoaded = () => {
    this.pastColorizedImageLoaded = true;
    this.loadingItemsCount--;
    if (
      this.pastImageLoaded &&
      this.presentImageLoaded &&
      this.loadingItemsCount <= 0
    ) {
      this.stopLoadingScreen(() => {
        this.focusOnImage();
      });
      this.updateColorized(this.colorized);
    }
    this.showColorized();
  };

  /// Generate wikipedia link
  getWikipediaLink = () => {
    if (this.state.element.link && this.state.element.link.length > 0) {
      return (
        <h2 className="wikipedia">
          <a href={this.state.element.link} target="_blank">
            <span>Wikipedia</span>
          </a>
        </h2>
      );
    }
    return "";
  };

  /// Generate alert strip
  getAlert = () => {
    if (this.state.element.alert && this.state.element.alert.length > 0) {
      return (
        <h3 className="row alert-text">
          <span>{"* " + this.state.element.alert}</span>
        </h3>
      );
    }
    return "";
  };

  /// Generate note strip
  getNote = () => {
    if (this.state.element.note && this.state.element.note.length > 0) {
      return (
        <h3 className="row note-text">
          <span>{this.state.element.note}</span>
        </h3>
      );
    }
    return "";
  };

  /// Generate legal info
  getLegalInfo = () => {
    let legalInfo = "";
    let photographer = "";

    // License old photo
    if (
      this.state.element.license_source &&
      this.state.element.license_source.length > 0
    ) {
      legalInfo += "Foto: " + this.state.element.license_source + ", ";
    }
    if (
      this.state.element.license_holder &&
      this.state.element.license_holder.length > 0
    ) {
      legalInfo += this.state.element.license_holder + " © ";
    }
    legalInfo += new Date().getFullYear();

    // Photographer new photo
    if (
      this.state.element.photographer &&
      this.state.element.photographer.length > 0
    ) {
      photographer += "Foto: " + this.state.element.photographer + ", ";
    }

    // Extra info
    let extra = [];
    if(this.state.element.legal){
      for(var i = 0; i < this.state.element.legal.length; i++){
        let note = this.state.element.legal[i];
        extra.push(<React.Fragment>{", "}</React.Fragment>);
        if(note.link.length > 0){
          extra.push(
              <a href={note.link} target="_blank">
                <span className="text-color-dark">
                  {note.text}
                </span>
              </a>
          );
        }else{
          extra.push(
              <span className="text-color-dark">
                {note.text}
              </span>
          );
        }
      }
    }

    return (
      <React.Fragment>
        <p className="text-color-dark">
          {legalInfo}{" "}
          <a href={this.state.element.license_link} target="_blank">
            <span className="text-color-dark">
              {this.state.element.license}
            </span>
          </a>
          {", "}
          <a href={"https://deoldify.ai/"} target="_blank">
            <span className="text-color-dark">
              {"DeOldify © " + new Date().getFullYear()}
            </span>
          </a>
          {", "}
          <a href={"https://www.google.com/earth/"} target="_blank">
            <span className="text-color-dark">
              {"Google Earth © " + new Date().getFullYear()}
            </span>
          </a>
          {", "}
          {photographer + "Historiefeber © " + new Date().getFullYear()}
          {" "}
          <a href="https://creativecommons.org/licenses/by-nc-sa/2.5/se/" target="_blank">
            <span className="text-color-dark">
              CC BY-NC-SA
            </span>
          </a>
          {extra}
        </p>
      </React.Fragment>
    );
  };

  /// Create page back / forward
  async setupNeighbours(collection, image) {
    let neighbours = await this.props.app.content.getElementNeighbours(
      collection,
      image
    );
    this.state.pageBackElement = neighbours.left;
    this.state.pageForwardElement = neighbours.right;
  }

  /// Main render
  render() {
    return (
      <div className="row viewer-wrapper">
        {/* Meta tags for link sharing format ( not working - need server side rendering )*/}
        <MetaTags>
          <title>{"Historiefeber - " + (this.state.element.name || "")}</title>
          <meta name="description" content={this.state.element.description} />
          <meta property="og:title" content={this.state.element.name} />
          <meta
            property="og:image"
            content={`https://historiefeber.se/media/lowres/${this.props.match.params.image}_0.jpg`}
          />
        </MetaTags>

        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="row background-color-light bottom-space top-space row-shadow">
            <div className="col-lg-3 col-md-2"></div>
            <div className="col-lg-6 col-md-8 content">
              <div className="row">
                <div
                  className="go-back col-lg-3 col-md-2 col-sm-2 col-2 text-color-light"
                  onClick={this.onBackClick}
                  title="Tillbaka"
                >
                  <img src="/resources/arrow-left.svg" />
                </div>
                <div className="title col-lg-6 col-md-8 col-sm-8 col-8 text-color-light">
                  <h1>{this.state.element.name}</h1>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-2"></div>
          </div>

          <div className="row background-color-dark bottom-space row-shadow">
            <div className={this.getFullscreenClassesSide()}></div>
            <div className={this.getFullscreenClasses()}>
              <div className="row">
                <div id={"viewer"} className="viewer col-lg-12">
                  <div
                    className="viewer-container"
                    onMouseDown={this.onViewerMouseDown}
                    onMouseUp={this.onViewerMouseUp}
                    onMouseMove={this.onViewerMouseMove}
                    onMouseLeave={this.onViewerMouseLeave}
                    onTouchStart={this.onViewerMouseDown}
                    onTouchEnd={this.onViewerMouseUp}
                    onTouchMove={this.onViewerMouseMove}
                    ref={this.viewer}
                  >
                    <div className="present">
                      <img
                        draggable="false"
                        src={this.state.present_image}
                        onLoad={this.presentLoaded}
                        style={this.state.imageStyle}
                      ></img>
                    </div>
                    <div className="past" style={this.state.splitStyle}>
                      <div
                        draggable="false"
                        style={{
                          ...this.state.pastImgStyle,
                          ...this.state.imageStyle,
                        }}
                      ></div>
                      <img
                        className="image-dummy"
                        style={{ display: "none" }}
                        draggable="false"
                        src={this.state.past_image}
                        onLoad={this.pastLoaded}
                      ></img>
                      <img
                        className="colorize-dummy"
                        style={{ display: "none" }}
                        draggable="false"
                        src={this.state.past_colorized_image_src}
                        onLoad={this.pastColorizedLoaded}
                      ></img>
                    </div>
                    <div
                      style={this.state.loadingStyle}
                      className="loading"
                    ></div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className=""></div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 content settings-container">
                  <div className="row">
                    <div
                      style={this.state.colorizedButtonStyle}
                      className="color-toggle flex-fill"
                    >
                      <div className="row">
                        <div className="col-lg-6 col-md-6- col-sm-6 col-6">
                          <img title="Färglägg" src="/resources/color.svg" />
                        </div>
                        <div className="col-lg-6 col-md-6- col-sm-6 col-6">
                          <label title="Färglägg" className="toggle-control">
                            <input
                              checked={this.state.forceColorizedCheckbox}
                              type="checkbox"
                              onClick={this.onColorize}
                            />
                            <span className="control"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="fullscreen-toggle flex-fill d-lg-block d-md-block d-sm-none d-none">
                      <div className="row">
                        <div className="col-lg-6 col-md-6- col-sm-6 col-6">
                          <img title="Förstora" src="/resources/expand.svg" />
                        </div>
                        <div className="col-lg-6 col-md-6- col-sm-6 col-6">
                          <label title="Förstora" className="toggle-control">
                            <input
                              type="checkbox"
                              onClick={this.onFullscreen}
                            />
                            <span className="control"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=""></div>
              </div>
            </div>
            <div className={this.getFullscreenClassesSide()}></div>
          </div>

          <div className="row background-color-light bottom-space row-shadow">
            <div className="col-lg-3 col-md-2"></div>
            <div className="col-lg-6 col-md-8 content">
              <div className="info-box">
                <h1 className="text-color-light">{this.state.element.name}</h1>
                <h3 className="text-color-base">
                  {this.state.element.address}
                </h3>
                <h2 className="text-color-dark">
                  {this.state.element.year + " | " + this.state.element.created}
                </h2>
                <h3 className="text-color-base">
                  {this.state.element.description}
                </h3>
                {this.getWikipediaLink()}
                {this.getNote()}
                {this.getAlert()}
                <br></br>
                {this.getLegalInfo()}
              </div>
            </div>
            <div className="col-lg-3 col-md-2"></div>
          </div>

          <div className="row background-color-dark bottom-space row-shadow">
            <div className="col-lg-3 col-md-2"></div>
            <div className="col-lg-6 col-md-8 content">
              <div className="map-viewer">
                <Map
                  reference={this.map}
                  content={this.props.app.content}
                  viewer={this}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-2"></div>
          </div>
          <div className="row background-color-light bottom-space top-space row-shadow">
            <div className="col-lg-3 col-md-2"></div>
            <div className="col-lg-6 col-md-8 content">
              <div className="row">
                <div
                  className="page-back col-lg-6 col-md-6 col-sm-6 col-6 text-color-light"
                  onClick={this.onPageBack}
                >
                  <div className="row">
                    <img
                      className="col-lg-3 col-md-3 col-sm-3 col-3"
                      src="/resources/arrow-left.svg"
                    />
                    <h1 className="text-color-light col-lg-7 col-md-7 col-sm-9 col-9">
                      {this.state.pageBackElement
                        ? this.state.pageBackElement.name
                        : ""}
                    </h1>
                    <div className="col-lg-2 col-md-2"></div>
                  </div>
                </div>
                <div
                  className="page-forward col-lg-6 col-md-6 col-sm-6 col-6 text-color-light"
                  onClick={this.onPageForward}
                >
                  <div className="row">
                    <div className="col-lg-2 col-md-2"></div>
                    <h1 className="text-color-light col-lg-7 col-md-7 col-sm-9 col-9">
                      {this.state.pageForwardElement
                        ? this.state.pageForwardElement.name
                        : ""}
                    </h1>
                    <img
                      className="col-lg-3 col-md-3 col-sm-3 col-3"
                      src="/resources/arrow-left.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-2"></div>
          </div>
        </div>
      </div>
    );
  }
}
export default PreProcessor(Viewer);

/// Component PreProcessor ( Passes various hooks )
function PreProcessor(Component) {
  return function WrappedComponent(props) {
    const { collection, image } = useParams();
    const history = useHistory();
    const match = useRouteMatch({
      path: "/:collection/:image",
      strict: true,
      sensitive: true,
    });
    return (
      <Component
        {...props}
        ref={props.reference}
        history={history}
        match={match}
      />
    );
  };
}
