import React, { Component } from "react";
import { useParams, useHistory, useRouteMatch } from "react-router-dom";
import MetaTags from "react-meta-tags";

import "components/admin/admin.css";
import AdminMap from "components/admin/adminmap.jsx";

/// Main image viewer
class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      element: {},
    };

    this.map = React.createRef();
    this.viewer = React.createRef();

    this.initialize(
      this.props.match.params.collection,
      this.props.match.params.image
    );
  }

  /// On props change
  componentWillReceiveProps(props) {
    if (this.props != props) {
      this.props = props;
      this.initialize(
        props.match.params.collection,
        props.match.params.image,
        false
      );
    }
  }

  /// Change current element viewed
  async initialize(collection, image) {
    if (this.props.app.headerRef.current) {
      this.props.app.headerRef.current.resetTextBox();
    }

    await this.props.app.content.waitForContent();

    this.props.app.content.getElement(collection, image, (element) => {
      this.setState({ element: element });

      if (element != undefined) {
        if (this.map) {
          if (this.map) {
            this.map.current.setPosition({
              lat: element.lat,
              lng: element.lng,
            });
          }
        }
      } else {
        this.props.app.content.getCollection(
          collection,
          (items, collection) => {
            if (collection != undefined) {
              if (this.map) {
                this.map.current.setPosition({
                  lat: collection.position.lat,
                  lng: collection.position.long,
                });
              }
            }
          }
        );
      }
    });
  }

  /// Main render
  render() {
    return (
      <div className="row viewer-wrapper">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="row background-color-dark bottom-space row-shadow">
            <div className="col-lg-3 col-md-2"></div>
            <div className="col-lg-6 col-md-8 content">
              <div className="admin-map-viewer">
                <AdminMap
                  reference={this.map}
                  content={this.props.app.content}
                  viewer={this}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-2"></div>
          </div>
        </div>
      </div>
    );
  }
}
export default PreProcessor(Admin);

/// Component PreProcessor ( Passes various hooks )
function PreProcessor(Component) {
  return function WrappedComponent(props) {
    const { collection, item } = useParams();
    const history = useHistory();
    const match = useRouteMatch({
      path: "/admin/:collection/:image?",
      strict: true,
      sensitive: true,
    });
    return (
      <Component
        {...props}
        ref={props.reference}
        history={history}
        match={match}
      />
    );
  };
}
