import React, { Component } from "react";
import { useParams, useHistory, useRouteMatch } from "react-router-dom";

import "components/element.css";
import "bootstrap/dist/css/bootstrap.css";

/// Element in collection menu
class Element extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,

      visibleStyle: {
        display: "block",
      },
      heightStyle: {
        height: "90px",
      },
    };
  }

  /// Set primary style ( used for searching )
  setPrimary = (value) => {
    this.setState({
      heightStyle: {
        height: value ? "120px" : "90px",
        transform: value
          ? "perspective(600px) rotate3d(0, 1, 0.05, 15deg) scale(1.08)"
          : "scale(1.0)",
      },
    });
  };

  /// On element click event
  onClick = (event) => {
    this.props.app.headerRef.current.resetTextBox();
    this.props.app.containerRef.current.expandNone();
    /*this.props.history.push(
      "/" + this.props.data.collection + "/" + this.props.data.content
    );*/
  };

  getIsNew = () => {
    if (this.props.data.is_new) {
      return (
        <div className="element-overlay-new background-color-positive text-color-base d-lg-block d-md-block d-sm-block d-block">
          {"Ny!"}
        </div>
      );
    }
  };

  /// On thumbnail load
  onLoad = () => {};

  /// Main render
  render() {
    return (
      <a
        style={this.state.visibleStyle}
        className="element-container col-lg-3"
        onClick={this.onClick}
        href={"./" + this.props.data.collection + "/" + this.props.data.content}
      >
        <div style={this.state.heightStyle} className="element">
          <div
            style={{
              backgroundImage: `url("${this.props.data.content_path_thumbnail_new}")`,
            }}
            className="element-background"
          ></div>
          <img
            style={{ display: "none" }}
            onLoad={this.onLoad}
            src={`url("${this.props.data.content_path_thumbnail_new}")`}
          />
          <div className="element-overlay"></div>
          <div className="element-overlay-shine"></div>
          <h1 className="element-title m-2 text-color-light">
            {this.state.data.name}
          </h1>
          <h1 className="element-subtitle text-color-dark">
            {this.state.data.year}
          </h1>
        </div>
        {this.getIsNew()}
      </a>
    );
  }
}
export default PreProcessor(Element);

/// Component PreProcessor ( Passes various hooks )
function PreProcessor(Component) {
  return function WrappedComponent(props) {
    const history = useHistory();
    const match = useRouteMatch({
      path: "/:collection/:image?",
      strict: true,
      sensitive: true,
    });
    return (
      <Component
        {...props}
        ref={props.reference}
        history={history}
        match={match}
      />
    );
  };
}
