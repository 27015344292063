import React, { Component } from "react";
import { Marker } from "@react-google-maps/api";

/// Google Maps clickable marker
class GoogleMapsMarker extends Component {
  MapIconUnfinished = new window.google.maps.MarkerImage(
    "/resources/pin-red.svg",
    null,
    null,
    null,
    new window.google.maps.Size(32, 32)
  );

  MapIconFinished = new window.google.maps.MarkerImage(
    "/resources/pin-green.svg",
    null,
    null,
    null,
    new window.google.maps.Size(32, 32)
  );

  constructor(props) {
    super(props);

    this.state = {
      finished: this.props.finished,
    };
  }

  /// Set marker finished
  setFinished = (value) => {
    this.setState({ finished: value });
  };

  /// On marker click event
  onMarkerClick = (event) => {
    window.open(this.props.data.source_link);
  };

  /// Main render
  render() {
    return (
      <Marker
        icon={
          this.state.finished ? this.MapIconFinished : this.MapIconUnfinished
        }
        position={{
          lat: this.props.data.lat,
          lng: this.props.data.lng,
        }}
        onClick={this.onMarkerClick}
        key={this.props.data.uniqueId}
      />
    );
  }
}
export default GoogleMapsMarker;
